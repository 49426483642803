<template>
    <div id="ag-grid">


        <div v-if="showHeader">
            <div v-if="add">
                <div v-if="withFilters">
                    <b-row>
                        <b-col cols="12" sm="5">
                            <h2>{{ pageTitle }}</h2>
                        </b-col>
                        <b-col cols="12" sm="7" class="text-sm-center text-right">
                            <b-row>
                                <b-col cols="12" md="7" class="mb-2 mb-md-0">
                                    <b-form-input size="sm" @input="updateSearchQuery" placeholder="Recherche"
                                        type="text" class="d-inline-block" />
                                </b-col>
                                <b-col cols="12" md="5">
                                    <b-button-group size="sm" class="d-flex justify-content-md-end">
                                        <b-button variant="outline-primary" class="btn-icon" @click="exportToExcel">
                                            <feather-icon icon="DownloadIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="printList">
                                            <feather-icon icon="PrinterIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="refresh"
                                            icon="file_download">
                                            <feather-icon icon="RefreshCwIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon"
                                            @click="$emit('plusClicked')">
                                            <feather-icon icon="PlusSquareIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="toggleCollapse">
                                            <feather-icon :icon="collapseIcon" />
                                        </b-button>
                                    </b-button-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <component v-show="collapseVisible" :is="filterComponent" @filterClicked="filterClicked"
                        :options="filterComponentData"></component>
                </div>

                <div v-else>
                    <b-row>
                        <b-col cols="12" sm="6">
                            <h2>{{ pageTitle }}</h2>
                        </b-col>
                        <b-col cols="12" sm="6" class="text-sm-center text-right">
                            <b-row>
                                <b-col cols="12" md="8" class="mb-2 mb-md-0">
                                    <b-form-input size="sm" @input="updateSearchQuery" placeholder="Recherche"
                                        type="text" class="d-inline-block" />
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-button-group size="sm" class="d-flex justify-content-md-end">
                                        <b-button variant="outline-primary" class="btn-icon" @click="exportToExcel">
                                            <feather-icon icon="DownloadIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="printList">
                                            <feather-icon icon="PrinterIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="refresh">
                                            <feather-icon icon="RefreshCwIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon"
                                            @click="$emit('plusClicked')">
                                            <feather-icon icon="PlusSquareIcon" />
                                        </b-button>
                                    </b-button-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div v-else>
                <div v-if="withFilters">
                    <b-row>
                        <b-col cols="12" sm="6">
                            <h2>{{ pageTitle }}</h2>
                        </b-col>
                        <b-col cols="12" sm="6" class="text-sm-center text-right">
                            <b-row>
                                <b-col cols="12" md="8" class="mb-2 mb-md-0">
                                    <b-form-input size="sm" @input="updateSearchQuery" placeholder="Recherche"
                                        type="text" class="d-inline-block" />
                                </b-col>
                                <b-col cols="12" md="4">
                                    <b-button-group size="sm" class="d-flex justify-content-md-end">
                                        <b-button variant="outline-primary" class="btn-icon" @click="exportToExcel">
                                            <feather-icon icon="DownloadIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="printList">
                                            <feather-icon icon="PrinterIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="refresh"
                                            icon="file_download">
                                            <feather-icon icon="RefreshCwIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="toggleCollapse">
                                            <feather-icon :icon="collapseIcon" />
                                        </b-button>
                                    </b-button-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <component v-show="collapseVisible" :is="filterComponent" @filterClicked="filterClicked"
                        :options="filterComponentData"></component>
                </div>

                <div v-else>
                    <b-row>
                        <b-col cols="12" sm="6">
                            <h2>{{ pageTitle }}</h2>
                        </b-col>
                        <b-col cols="12" sm="6" v-if="disabledBtn">
                            <b-form-input size="sm" ref="search" @input="updateSearchQuery" placeholder="Recherche"
                                type="text" class="d-inline-block" />
                        </b-col>
                        <b-col cols="12" sm="6" class="text-sm-center text-right" v-else>
                            <b-row>
                                <b-col cols="12" md="9" class="mb-2 mb-md-0">
                                    <b-form-input size="sm" @input="updateSearchQuery" placeholder="Recherche"
                                        type="text" class="d-inline-block" />
                                </b-col>
                                <b-col cols="12" md="3">
                                    <b-button-group size="sm" class="d-flex justify-content-md-end">
                                        <b-button variant="outline-primary" class="btn-icon" @click="exportToExcel">
                                            <feather-icon icon="DownloadIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="printList">
                                            <feather-icon icon="PrinterIcon" />
                                        </b-button>
                                        <b-button variant="outline-primary" class="btn-icon" @click="refresh">
                                            <feather-icon icon="RefreshCwIcon" />
                                        </b-button>
                                    </b-button-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>



        <hr>

        <ag-grid-vue :gridOptions="gridOptions" :headerHeight="32" :rowHeight="50"
            :class="isDark ? 'ag-theme-balham-dark' : 'ag-theme-balham'" style="width: 100%; height: 53vh;"
            :defaultColDef="defaultColDef" @grid-ready="onGridReady" :pagination="true" paginationPageSize="30"
            :columnDefs="agGrid.columnDefs" :rowData="agGrid.rows" :overlayLoadingTemplate="overlayLoadingTemplate">
        </ag-grid-vue>

    </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import '@/assets/ag-grid/ag-theme-balham.css';
import '@/assets/ag-grid/ag-theme-balham-dark.css';
import {
    AgGridVue
} from 'ag-grid-vue';
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import frenshVersion from "./frenshAgGrid";

export default {
    components: {
        AgGridVue
    },
    props: {
        agGrid: {
            type: Object,
            required: true,
        },
        pageTitle: {
            type: String,
        },
        withFilters: {
            type: Boolean,
        },
        filterComponent: {
            type: Object
        },
        filterComponentData: {
            type: Object
        },
        add: {
            type: Boolean
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        disabledBtn: {
            type: Boolean
        },
        autoSizeColumns: {
            type: Boolean
        },
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')
        return { skin, isDark }
    },
    data() {
        return {
            overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Merci de patienter pendant le chargement des données...</span>',
            gridOptions: {
                localeText: frenshVersion,
                rowSelection: "multiple",
                suppressCellSelection: true,
                enableCellTextSelection: true,
                // suppressHorizontalScroll: false,
                // enableSorting: true,
            },
            defaultColDef: {
                onCellDoubleClicked: this.handleCellDoubleClick,
                onCellClicked: this.onCellClicked,
                onCellValueChanged : this.onCellValueChanged,
                sortable: true,
                resizable: true,
                suppressToolPanel: false,
                filter: true,
                cellStyle: {
                    // 'font-size': '.950rem',
                    // 'vertical-align': 'middle',
                    'font-weight': '500',
                    // 'font-weight': 'bold',
                    'border': '1px solid #EBE9F1',
                    'border-width': '1px',
                    'font-family': "'Montserrat', Helvetica, Arial, serif"
                },
            },
            gridApi: null,
            columnApi: null,
            collapseVisible: false,
        }
    },
    computed: {
        collapseIcon() {
            return this.collapseVisible ? "ChevronUpIcon" : "ChevronDownIcon";
        },
    },
    methods: {
        onCellValueChanged(params){
            this.$emit('onCellValueChanged', params);
        },
        onCellClicked(params) {
            this.$emit('onCellClicked', params);
        },
        handleCellDoubleClick(params) {
            this.$emit('cellDoubleClicked', params);
        },
        filterClicked(obj) {
            this.collapseVisible = !this.collapseVisible;
            this.$emit('filterClicked', obj)
        },
        onFirstDataRendered(params) {
            this.$emit('onFirstDataRendered');
            params.api.hideOverlay();
        },
        toggleCollapse() {
            this.collapseVisible = !this.collapseVisible;
        },
        exportToExcel() {
            const data = this.agGrid.rows;
            // Exclude columns with names "odata.etag" and "ETag"
            const excludedColumns = ['odata.etag', 'ETag', '@odata.etag', ""];
            const filteredData = data.map(obj => {
                // Filter out the excluded columns from each object
                const filteredObj = {};
                for (const key in obj) {
                    if (!excludedColumns.includes(key)) {
                        filteredObj[key] = obj[key];
                    }
                }
                return filteredObj;
            });

            // Extract the keys from the first object to create the header row
            const header = Object.keys(filteredData[0]);

            // Create an array of arrays, where each inner array represents a row
            const rows = filteredData.map(obj => Object.values(obj));

            // Insert the header row at the beginning of the array
            rows.unshift(header);

            // Convert the array of arrays to a worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(rows);

            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Write the workbook data to an Excel file
            const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, this.pageTitle + '.xlsx');
        },
        printList() {
            this.$emit('printClicked');
            //             // Get the visible columns
            //             const visibleColumns = this.gridOptions.columnApi.getAllDisplayedColumns();

            //             // Get the list data
            //             const data = this.agGrid.rows;

            //             // Columns to exclude
            //             const excludedColumns = ['odata.etag', 'ETag', '@odata.etag', ""];

            //             // Build the HTML content for printing
            //             let html = `<html><head>
            //     <title>${this.pageTitle}</title>
            //     <style>
            //       /* Add your CSS styles here */
            //       table {
            //         border-collapse: collapse;
            //         width: 100%;
            //       }

            //       th, td {
            //         border: 1px solid black;
            //         padding: 8px;
            //         text-align: left;
            //       }

            //       th {
            //         background-color: lightgray;
            //       }
            //     </style>
            //   </head><body>`;

            //             // Generate the table from the data
            //             html += '<table>';

            //             // Add table headers
            //             html += '<thead><tr>';
            //             visibleColumns.forEach((column) => {
            //                 const key = column.getColDef().field;
            //                 if (!excludedColumns.includes(key)) {
            //                     html += `<th>${key}</th>`;
            //                 }
            //             });
            //             html += '</tr></thead>';

            //             // Add table rows
            //             html += '<tbody>';
            //             data.forEach((item) => {
            //                 html += '<tr>';
            //                 visibleColumns.forEach((column) => {
            //                     const key = column.getColDef().field;
            //                     if (!excludedColumns.includes(key)) {
            //                         html += `<td>${item[key]}</td>`;
            //                     }
            //                 });
            //                 html += '</tr>';
            //             });
            //             html += '</tbody>';

            //             // Close the HTML content
            //             html += '</table></body></html>';

            //             // Print the HTML content
            //             const printWindow = window.open('', '_blank');
            //             printWindow.document.open();
            //             printWindow.document.write(html);
            //             printWindow.document.close();
            //             printWindow.print();
        },
        refresh() {
            this.$emit('refresh');
        },
        onGridReady(params) {
            params.api.showLoadingOverlay();
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
            if (!this.autoSizeColumns)
                if (!/Mobi/i.test(navigator.userAgent))
                    this.gridApi.sizeColumnsToFit();
            this.$emit('onGridReady');
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val);
        },
    }
}
</script>

<style scoped>
@media (max-width: 769px) {
    .ag-theme-balham {
        height: 80vh ! important;
    }
}
</style>